class TrailerBuilder {

    constructor (apiKey) {
        this.default_trailer = typeof(_tid) === "undefined" ? NaN : parseInt(_tid);
        this.external_submit_url = typeof(_external_submit_url) === "undefined" ? "" : _external_submit_url;
        this.hide_h1 = typeof(_hide_h1) === "undefined" ? false : _hide_h1;
        this.hide_customer_pics = typeof(_hide_customer_pics) === "undefined" ? false : _hide_customer_pics;
        this.allow_external_links = typeof(_allow_external_links) === "undefined" ? false : _allow_external_links;
        this.navigation_locked = !isNaN(this.default_trailer)
        this.affiliate = apiKey;
        this.analyticsLoaded = false;
        if (window.location.href.indexOf("localhost") >= 0) {
            this.baseUrl = "https://localhost:8085/";
        } else {
            this.baseUrl = "https://builder.doubledtrailers.com/";
        }
        this.models = [];
        this.currentModel = 0;

        this.pageUrl = window.location.origin + window.location.pathname;
        this.directSelection = false;
        this.tabCount = 0;

        // Load all trailer options
        /*jQuery.get(this.baseUrl + 'api/builder?key=' + apiKey).done((data) => {
           this.models = data;
           this.initializeBuilder();
        });*/
        var scriptLoc = window.location.protocol + "//" + window.location.host + window.location.pathname;
        var fetchUrl = this.baseUrl + 'api/builder?key=' + apiKey + "&source=" + encodeURIComponent(scriptLoc)
        if (this.navigation_locked) {
            fetchUrl += "&tid=" + this.default_trailer
        }
        fetch(fetchUrl,
            {
               method: "GET"
            })
            .then((response) => {
                return response.json();
            })
            .then ((data) => {
                this.models = data;
                if (!isNaN(this.default_trailer)) {
                    this.openModel(this.default_trailer);
                    this.doTabNav();
                } else {
                    this.initializeBuilder();
                }
            }
        );

        fetch(this.baseUrl + 'api/builder/branding?key=' + apiKey,
            {
                method: "GET"
            })
            .then((response) => {
                return response.json();
            })
            .then ((data) => {
                    this.backlink = data.backlink;
                    this.addBacklink();
            });

        window.addEventListener('hashchange', this.doTabNav);

        this.addModalToDocument();
    }

    initializeBuilder() {
        var availableCategories = this.models.map(x=> x["category1"] );
        var distinctAvailableCategories = [... new Set(availableCategories)];

        var classSelector = "";
        var logo = `<div class="trailerclass-logo"><img alt="Double D Trailers" src="https://www.doubledtrailers.com/assets/images/main-logo.png"></div>`;

        distinctAvailableCategories.forEach(x=> {
           if (x === "Gooseneck Horse Trailers") {
               classSelector += `<div data-trailerclass='${x}' class='trailerclass'><div class="trailerclass-row">${logo}<h2 class="trailerclass-header">${x}</h2></div><img src="https://www.doubledtrailers.com/assets/images/client-photos/SL_gooseneck2-min.jpg"><hr><p>Horse Trailers that are Easy to Tow with Less Weight</p></div>`;
           } else if (x === "Bumper Pull Horse Trailers") {
               classSelector += `<div data-trailerclass='${x}' class='trailerclass'><div class="trailerclass-row">${logo}<h2 class="trailerclass-header">${x}</h2></div><img src="https://www.doubledtrailers.com/assets/images/home-trailer2.jpg"><hr><p>Horse Trailers Built for Safety and Durability to Protect Your Horses</p></div>`;
           } else if (x === "Living Quarters Horse Trailers") {
               classSelector += `<div data-trailerclass='${x}' class='trailerclass'><div class="trailerclass-row">${logo}<h2 class="trailerclass-header">${x}</h2></div><img src="https://www.doubledtrailers.com/assets/images/home-trailer3.jpg"><hr><p>Luxury Living Quarters</p></div>`;
           }
        });
        classSelector = "<div class='trailerCTA'><div class='trailerCTA1'>Design your own custom horse trailer!</div><div class='trailerCTA2'>Click a model to get started with a <i><strong>FREE</strong></i> price quote</div></div><div class='trailerclasses'>" + classSelector + "</div><div id='trailerbrand'>" + this.backlink + "</div>";
        document.getElementById("dd-builder").innerHTML = classSelector;
        document.getElementById("dd-builder").querySelectorAll(".trailerclass").forEach((trailerClass) => {
            trailerClass.addEventListener("click", (evt) => {this.openClass(trailerClass.dataset.trailerclass);});
        });

        document.getElementById("dd-summary").innerHTML = `<div class="summary-pane">
<p class="summary-needhelp-header">Need Help?</p>
<p class="summary-needhelp">If you're not sure what you're looking for, fill in your contact information below and we'll help you sort things out.</p>

<form method="post" id="dd-assistanceForm" action="${this.baseUrl}api/builder/contact">
<input type="hidden" name="affiliate" value="${this.affiliate}">
<input name="name" required class="summary-input" type="text" value="" placeholder="Full Name *">
<input name="email" class="summary-input" type="email" value="" placeholder="Email *">
<input name="phone" class="summary-input" type="tel" value="" placeholder="Phone">
<input type="submit" name="Submit" id="dd-contact-button" class="summary-submitbutton" value="Help Me Build a Trailer">
</form>
</div>
`;

        document.getElementById("dd-assistanceForm").addEventListener("submit", (evt) => {
            this.applyInteractionTag();
            this.applyContactTag();

            var theForm = evt.target;
            fetch(theForm.action, {
                method: 'POST',
                body: new FormData(theForm),
            }).then(function (response) {
                if (response.ok) {
                    document.getElementById("dd-summary").innerHTML = `<div class="summary-pane">
<p class="summary-needhelp-header">Thank you!</p><p class="summary-needhelp">Your message has been received. We will be in touch soon.</p></div></div>`;
                } else {
                    return Promise.reject(response);
                }
            }).catch(function (error) {
                window.alert("Sorry, there was an error submitting your form. Please try again, or visit us at https://doubledtrailers.com/")
            });
            evt.preventDefault();
        });
    }

    addBacklink() {
        const brand = document.getElementById("trailerbrand");
        if (brand != null) {
            brand.innerHTML = this.backlink;
        }
    }

    openClass(trailerClass) {
        this.applyInteractionTag();
        var availableModels = this.models.filter(x=> x["category1"] == trailerClass);
        //var distinctavailableModels = [... new Set(availableModels)];

        var trailerSelector = "<p class='builder-badge builder-badge-link builder-reset-everything'>&laquo; Back</p>";

        var currentCategory = "";
        availableModels.forEach(function(model) {
            if (currentCategory != model["category2"]) {
                trailerSelector += `<h2 class='subtype'>${model["category2"]}</h2>`;
                currentCategory = model["category2"];
            }
            trailerSelector += `<div data-tid="${model["tid"]}" class='builder-selector-model builder-link'>
<img class="builder-selector-model-img" src="${model["trailerPhoto"]}">
<div class="builder-selector-model-summary"><h2>${model["title"]}</h2>${model["overview"]}</div>`;
            trailerSelector += `<div class="builder-basecost">$${formatMoney(model["cost"], 2)} USD</div>`
            trailerSelector += `<button class="builder-actionbutton">View and Customize</button>`
            trailerSelector += `</div>`;
        });
        document.getElementById("dd-builder").innerHTML = trailerSelector;


        // Summary external hyperlinks
        if (!this.allow_external_links) {
            document.getElementById("dd-builder").querySelectorAll(".builder-selector-model-summary a").forEach(element => {
                element.addEventListener("click", (evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    var targetLink = evt.currentTarget.getAttribute("href");
                    this.openInfobox(targetLink);
                });
            });
        }

        document.getElementById("dd-builder").querySelectorAll(".builder-selector-model").forEach((trailerModel) => {
            trailerModel.addEventListener("click", (evt) => {this.openModel(trailerModel.dataset.tid);});
        });

        document.getElementById("dd-builder").querySelector(".builder-reset-everything").addEventListener("click", () => this.initializeBuilder());

    }

    openModel(trailerId) {
        this.tabCount = 0;
        this.currentModel = trailerId;
        var presets = this.getPresets();
        var trailer = this.models.find(x=>x.tid == parseInt(trailerId));

        // Customize tab
        var trailerDetails = [];

        var panelStarted = false;
        trailer.options.forEach(option=>{
            var optionPresetOverride = presets["opt" + option.oid];
            if (typeof(optionPresetOverride) !== "undefined") {
                option.defaultvalue = optionPresetOverride;
            }
            if (option.type==="Label") {
                if (panelStarted) {
                    this.endPanel(trailerDetails);
                }
                this.startPanel(trailerDetails, option.title);
                panelStarted = true;
            } else {
                this.addOption(trailerDetails, option);
            }
        });

        if (panelStarted) {
            this.endPanel(trailerDetails);
        }

        this.addStripePanel(trailerDetails);

        trailerDetails.push("<button class=\"summary-submitbutton\">View my trailer summary</button>");

        var floorPlans = [];
        if (trailer.floorplanImage) {
            floorPlans.push(`<div style="margin: 8px 0;"><img loading="lazy" src="${trailer.floorplanImage}" class="builder-details-floorplan-img"></div>`);
        }
        if (trailer.floorplanPDF) {
            floorPlans.push(`<div style="margin: 8px 0;"><a href="${trailer.floorplanPDF}" class="builder-details-floorplan-link">Download this Floorplan (PDF Document)</a></div>`);
        }

        var photos = this.buildPhotosTab(trailer);
        var videos = this.buildVideosTab(trailer);

        // Rest of the tabs
        var tabStrip = [];
        var allTabs = [];
        this.addBackButton(tabStrip);
        if (!this.hide_h1) {
            this.addModel(tabStrip, trailer);
        }
        tabStrip.push(`<div class="builder-details-tabselector">`);
        var summary = `<div class="builder-overview"><div class="builder-overview-image"><img loading="lazy" src="${trailer.trailerPhoto}" alt="${trailer.title}"></div><div class="builder-overview-overview">${trailer.overview}</div></div><div class="trailer-overview-summary">${trailer.summary}</div>`;
        this.addTab("Customize", trailerDetails.join(""), tabStrip, allTabs, false, "tab-customize");
        this.addTab("More Info", summary, tabStrip, allTabs, true, "tab-overview");
        this.addTab("Standards", trailer.features, tabStrip, allTabs, true, "tab-link");
        this.addTab("Sample Pics", photos.join(""), tabStrip, allTabs, true, "tab-photos");
        if (!this.hide_customer_pics) {
            this.addTab("Customer Pics", trailer.facebookPhotos, tabStrip, allTabs, true, "tab-facebook");
        }
        //this.addTab("Videos", videos.join(""), tabStrip, allTabs, true);
        this.addTab("Floorplans", floorPlans.join(""), tabStrip, allTabs, true, "tab-floorplans");
        tabStrip.push(`</div>`);

        document.getElementById("dd-builder").innerHTML = tabStrip.join("") + allTabs.join("");

        // Tabstrip clicks
        document.getElementById("dd-builder").querySelectorAll(".builder-details-tab a").forEach(element => {
            element.addEventListener("click", (evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                var theTab = evt.currentTarget.dataset.tabid;

                // Hide all other tabs
                document.querySelectorAll(".builder-details-tabcontent").forEach(element => {
                    element.classList.remove("open")
                });
                document.getElementById("tab-" + theTab).classList.add("open");

                // Active tab
                document.querySelectorAll(".builder-details-tab").forEach(element => {
                    element.classList.remove("open")
                });
                evt.currentTarget.parentNode.classList.add("open");

                const elementRect = evt.currentTarget.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.pageYOffset;
                const middle = absoluteElementTop - (window.innerHeight / 4);
                window.scrollTo(0, middle);
            });
        });

        // Customize now events
        document.getElementById("dd-builder").querySelectorAll(".summary-docustomize").forEach(element => {
            element.addEventListener("click", (evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                var tabs = document.querySelectorAll(".builder-details-tab a");
                tabs[0].click();
            });
        });

        // Builder events
        document.getElementById("dd-builder").querySelectorAll(".builder-reset-link").forEach(element => {
            element.addEventListener("click", () => {this.initializeBuilder()});
        });

        document.getElementById("dd-builder").querySelectorAll(".builder-input").forEach(element => {
            element.addEventListener("change", () => {
                this.recalculateTotal(trailer.cost);
                this.saveTrailerState();
            });
        });

        document.getElementById("dd-builder").querySelectorAll(".builder-input-checkbox").forEach(element => {
            element.addEventListener("input", (evt) => {
                var checkboxGroup = evt.currentTarget.dataset["checkboxgroup"];
                var optionId = evt.currentTarget.id;
                if (checkboxGroup) {
                    var others = document.querySelectorAll(`.builder-input-checkbox[data-checkboxgroup="${checkboxGroup}"]:not(#${optionId})`);
                    others.forEach(x=>{
                            if (x.checked) {
                                x.checked = false;
                            }
                        }
                    )
                    this.saveTrailerState();
                }
            });
        });


        // Info panel external hyperlinks
        if (!this.allow_external_links) {
            document.getElementById("dd-builder").querySelectorAll(".builder-details-tabcontent a").forEach(element => {
                element.addEventListener("click", (evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    var targetLink = evt.currentTarget.getAttribute("href");
                    this.openInfobox(targetLink);
                });
            });
        }

        // Category Panel clicks
        document.getElementById("dd-builder").querySelectorAll(".builder-details-panel").forEach(element => {
            element.addEventListener("click", (evt) => {
                evt.stopPropagation();
                evt.currentTarget.classList.toggle("open");
                evt.currentTarget.classList.toggle("closed");
                slideToggle(evt.currentTarget.nextElementSibling, 500);
                evt.preventDefault();
            });
        });


        document.getElementById("dd-summary").innerHTML = `<div class="summary-pane">
    <div class='summary-title'>Your Trailer So Far</div>
    <hr>
    <div id="builder-report">
        <div class="summary-feature">${trailer.sku}</div>
        <div id="summary-content"></div>
    </div>
    <p class="summary-notfinished">Order not finished? No problem!</p>
    <p class="summary-instructions">You can always submit your unfinished order and we'll help you finish it off.</p>
    <button id="sendTrailer" class="summary-submitbutton">View my trailer summary</button>
</div>`;


        this.recalculateTotal(trailer.cost);

        document.querySelectorAll(".summary-submitbutton").forEach(element => {
            element.addEventListener("click", () => {
                document.getElementById("trailerdetails").value = document.getElementById("builder-report").innerText;
                document.getElementById("dd-builder-finish-form-external-trailerdetails").value = document.getElementById("builder-report").innerText;

                document.getElementById("trailersummary").innerHTML = document.getElementById("builder-report").innerHTML;
                document.getElementById("dd-builder-finish-form-external-trailerdetails-html").value = document.getElementById("builder-report").innerHTML;

                if (this.external_submit_url) {
                    document.getElementById("dd-builder-finish-form-external").submit();
                } else {
                    this.openModal();
                }
            });
        });

        document.getElementById("dd-builder").querySelectorAll(".builder-option-label").forEach((popup) => {
            popup.addEventListener("click", (evt) => {
                var element = evt.currentTarget;
                var description = element.dataset.description;
                var resource = element.dataset.resource;
                var series = element.dataset.series;
                var index =  parseInt(element.dataset.seriesindex);
                if (series && !isNaN(index)) {
                    var previousElement = document.querySelector(`[data-series='${series}'][data-seriesindex='${index-1}']`);
                    var nextElement = document.querySelector(`[data-series='${series}'][data-seriesindex='${index+1}']`);
                }
                this.openInfobox(resource, description, previousElement, nextElement);
            });
        });
    }

    buildPhotosTab(trailer) {

        var photos = [];
        trailer.photos.forEach((x,i)=>{
           var filename = x.substring(x.lastIndexOf('/')+1);
           var path = x.substring(0, x.lastIndexOf('/')+1);
           var filenameNoExtension = filename.replace(/\.[^/.]+$/, "");
           var thumbnail = path + filenameNoExtension + "-150x150.jpg";
           photos.push (`<div data-series="photo" data-seriesindex="${i}" class="builder-option-label builder-details-photo" data-resource=\"${htmlEncode(x)}\"><img src='${htmlEncode(x)}'></div>`);
        });
        return photos;
    }

    buildVideosTab(trailer)
    {
        var videos = [];
        trailer.videos.forEach(x=>{
            videos.push (`<iframe class="modal-infobox-iframe" width="480" height="360" src="${x}" frameborder="0" allowfullscreen="" id="mediaiframe-video"></iframe>`);
        });
        return videos;
    }

    recalculateTotal(baseprice) {
        var total = parseFloat(baseprice);
        var options = [];

        options.push(`<div class='summary-choice'><strong>Base price:</strong> $${formatMoney(baseprice)}</div>`);

        document.getElementById("dd-builder").querySelectorAll(".builder-input").forEach(element => {

            var choiceDescription = "";

            var optionType = element.tagName.toLowerCase();
            var theChoice;

            if (optionType == "input") {
                if (element.type == "text") {
                    if (element.value) {
                        theChoice = element;
                        choiceDescription = element.value;
                    }
                } else if (element.type == "checkbox") {
                    if (element.checked) {
                        theChoice = element;
                        total += parseFloat(element.dataset.price);
                        choiceDescription = `1 x ${element.dataset.label} ($${formatMoney(parseFloat(element.dataset.price))})`;
                    }
                } else if (element.type == "radio") {
                    if (element.checked) {
                        theChoice = element;
                        total += parseFloat(element.dataset.price);
                        choiceDescription = `${element.dataset.label} ($${formatMoney(parseFloat(element.dataset.price))})`;
                    }
                }
            } else if (optionType == "select") {
                if (element.selectedIndex > 0) {
                    theChoice = element.options[element.selectedIndex];
                    var lineprice = parseFloat(theChoice.dataset.price);
                    total += lineprice;
                    if (theChoice.dataset.usevalueaslabel && theChoice.dataset.usevalueaslabel == "true") {
                        choiceDescription = theChoice.text;
                    } else {
                        choiceDescription = `${theChoice.value} x ${element.dataset.label} ($${formatMoney(lineprice)})`;
                    }
                }
            }

            if (theChoice) {
                if (theChoice.dataset.prependlabel) {
                    choiceDescription = theChoice.dataset.prependlabel + " " + choiceDescription;
                }
            }

            if (choiceDescription) {
                options.push(`<div class='summary-choice'>${choiceDescription}</div>`);
            }
        });
        options.push(`<hr><div class="summary-totalrow">
    <div class="summary-totaltext">Total:</div>
    <div class="summary-subtotal">$${formatMoney(total,2)}</div>
</div>
<div class="summary-taxnotincluded">*Not including taxes or shipping</div>`)
        document.getElementById("summary-content").innerHTML = options.join("");
        document.getElementById("dd-summary").scrollTop = document.getElementById("dd-summary").scrollHeight;
    }

    addTab(tabName, tabContent, tabStrip, tabContentArea, doCustomizeButton, tabTarget) {
        tabContent = tabContent.replaceAll("<iframe ", "<iframe loading='lazy' ");
        tabContent = tabContent.replaceAll("<img ", "<img loading='lazy' ");
        this.tabCount++;
        var extraClass = (this.tabCount == 1) ? "open" : "";
        tabStrip.push(`<div id="${tabTarget}" data-tabid="${this.tabCount}" class="builder-details-tab ${extraClass}"><a data-tabid="${this.tabCount}" href="#">${tabName}</a></div>`)
        tabContentArea.push(`<div id="tab-${this.tabCount}" class="builder-details-tabcontent ${extraClass}">
${tabContent}`);
        if (doCustomizeButton) {
            tabContentArea.push(`<div class="builder-details-footercustomize"><button class="builder-actionbutton summary-docustomize">Customize my trailer</button></div>`);
        }
        tabContentArea.push("</div>");
    }

    doTabNav() {
        var tabHash = window.location.hash;
        if (tabHash.indexOf("tab-") == 1) {
            var tab = document.querySelector(tabHash + " a");
            if (tab) {
                tab.click();
            }
        }
    }

    addBackButton(trailerDetails) {
        if (!this.navigation_locked) {
            trailerDetails.push("<a class='builder-badge builder-badge-link builder-reset-link'>&laquo; Choose a different model</a>");
        }
    }

    addModel(trailerDetails, trailer) {
        trailerDetails.push(`<h1>${trailer.title}</h1>`);
    }

    addStripePanel(template) {
        this.startPanel(template, "Vinyl Stripe Color and Graphic");
        var presets = this.getPresets();
        var decal = {
            chinese: isChecked(presets["optdecal-chinese"]),
            horse: isChecked(presets["optdecal-horse"]),
            jazz: isChecked(presets["optdecal-jazz"]),
            splash: isChecked(presets["optdecal-splash"]),
            swirl: isChecked(presets["optdecal-swirl"]),
            symmetric: isChecked(presets["optdecal-symmetric"]),
            z: isChecked(presets["optdecal-z"])
        };
        var defaultColor = presets["optdecal-color"] ?? "";

        template.push(`
<p><strong>Get More Info!</strong> You can click on the title of each option to view details and photos for each option.</p>
<div class="builder-form-option"><div class="builder-form-col1">
<input type="radio" class="builder-input builder-input-checkbox" name="graphic" value="1" data-label="Chinese Writing" data-prependlabel="Graphic: " data-oid="decal-chinese" ${decal.chinese} data-price="0"></div>
<div class="builder-form-col2">
<span class="builder-option-label" data-description="Chinese Writing" data-resource="https://www.doubledtrailers.com/assets/images/Decals/chinese-writing.jpg">Chinese Writing</span>
</div></div>

<div class="builder-form-option">
<div class="builder-form-col1">
<input type="radio" class="builder-input builder-input-checkbox" name="graphic" value="1" data-label="Horse Head" data-prependlabel="Graphic: " data-oid="decal-horse" ${decal.horse} data-price="0"></div>
<div class="builder-form-col2">
<span class="builder-option-label" data-description="Horse Head" data-resource="https://www.doubledtrailers.com/assets/images/Decals/horsehead.jpg">Horse Head</span>
</div></div>

<div class="builder-form-option">
<div class="builder-form-col1">
<input type="radio" class="builder-input builder-input-checkbox" name="graphic" value="1" data-label="Jazz" data-prependlabel="Graphic: " data-oid="decal-jazz" ${decal.jazz} data-price="0"></div>
<div class="builder-form-col2">
<span class="builder-option-label" data-description="Jazz Decal" data-resource="https://www.doubledtrailers.com/assets/images/Decals/jazz.jpg">Jazz Decal</span>
</div></div>

<div class="builder-form-option">
<div class="builder-form-col1">
<input type="radio" class="builder-input builder-input-checkbox" name="graphic" value="1" data-label="Splash" data-prependlabel="Graphic: " data-oid="decal-splash" ${decal.splash} data-price="0"></div>
<div class="builder-form-col2">
<span class="builder-option-label" data-description="Splash Decal" data-resource="https://www.doubledtrailers.com/assets/images/Decals/splash.jpg">Splash Decal</span>
</div></div>

<div class="builder-form-option">
<div class="builder-form-col1">
<input type="radio" class="builder-input builder-input-checkbox" name="graphic" value="1" data-label="Swirl" data-prependlabel="Graphic: " data-oid="decal-swirl" ${decal.swirl} data-price="0"></div>
<div class="builder-form-col2">
<span class="builder-option-label" data-description="Swirl Decal" data-resource="https://www.doubledtrailers.com/assets/images/Decals/swirl.jpg">Swirl</span>
</div></div>

<div class="builder-form-option">
<div class="builder-form-col1">
<input type="radio" class="builder-input builder-input-checkbox" name="graphic" value="1" data-label="Symmetric" data-prependlabel="Graphic: " data-oid="decal-symmetric" ${decal.symmetric} data-price="0"></div>
<div class="builder-form-col2">
<span class="builder-option-label" data-description="Symmetric" data-resource="https://www.doubledtrailers.com/assets/images/Decals/symmetric.jpg">Symmetric</span>
</div></div>

<div class="builder-form-option">
<div class="builder-form-col1">
<input type="radio" class="builder-input builder-input-checkbox" name="graphic" value="1" data-label="Z" data-prependlabel="Graphic: " data-oid="decal-z" ${decal.z} data-price="0"></div>
<div class="builder-form-col2">
<span class="builder-option-label" data-description="Z Decal" data-resource="https://www.doubledtrailers.com/assets/images/Decals/z.jpg">Z Decal</span>
</div></div>

<br>
<p>Choose your colour from our <a href="https://www.doubledtrailers.com/assets/files/decal-color-guide.pdf" target="_blank">Color Guide (PDF)</a> and enter the name of it in the text field below.<br>
<br>
061 Black - Free<br>
015 Cardinal Red - Free<br>
083 Sapphire Blue - Free<br>
All others - $125 upcharge<br>
<br><input type="text" id="decal-color" data-prependlabel="Custom color: " value="${defaultColor}" class="text-field decal-color builder-input" data-oid="decal-color"></p>`);
        this.endPanel(template)
    }

    startPanel(template, panelName) {
        template.push (`<div class="builder-details-pane">`);
        template.push (
`<div class="builder-details-panel builder-link open">
    <div class="b-label builder-link">${panelName}</div>
    <div class="b-toggle"><span class="b-open">&#9660;</span><span class="b-close">&#9650;</span></div>
</div>
<div class="builder-details-options">`);
    }

    endPanel(template) {
        template.push(`</div></div>`);
    }

    addDropdown(template, elementId, optionId, choices, label = "", pricePerQty = 0, description = "", videoUrl = "", imageUrl = "") {
        template.push( `<div class="builder-form-option"><div class="builder-form-col1"><select id="${elementId}" data-oid="${optionId}" class="builder-input builder-input-dropdown" data-label="${label}" class="builder-input" >`);
        choices.forEach(x=> {
            // var price = (x.price > 0) ? (" + $" + formatMoney(x.price,0)) : "";
            var price = "";
            var optionSelected = x.selected ? "selected" : "";
            template.push (`<option data-prependlabel="${x.prependLabel}" data-usevalueaslabel="${x.useValueAsLabel ? "true" : "false"}" ${optionSelected} data-price="${x.price}" value="${x.key}">${x.value}${price}</option>`);
        });
        template.push ("</select></div><div class='builder-form-col2'>");

        template.push (` <span class="builder-option-label" `);
        if (description) {
            template.push (`data-description="${htmlEncode(description)}"`);
        }
        template.push (`data-resource="${htmlEncode(imageUrl)}">${label}</span>`);

        if (videoUrl) {
            template.push (` <img class="builder-option-label" src="${this.baseUrl}video.png" data-resource="${htmlEncode(videoUrl)}" data-description="${htmlEncode(description)}">`);
        }

        if (pricePerQty > 0) {
            template.push (` <span class="builder-option-label-price">($${formatMoney(pricePerQty)})</span>`);
        }
        template.push("</div>");
        template.push("</div>");
    }

    addCheckbox(template, elementId, optionId, label = "", prependLabel = "", pricePerQty = 0, description = "", videoUrl = "", imageUrl = "", defaultvalue = "", checkboxgroup = "") {
        var isChecked = (defaultvalue == "1") ? " checked='checked' " : "";
        template.push( `<div class="builder-form-option"><div class="builder-form-col1"><input type="checkbox" ${isChecked} data-oid="${optionId}" data-checkboxgroup="${checkboxgroup}" id="${elementId}" class="builder-input builder-input-checkbox" value="1" data-label="${label}" data-prependlabel="${prependLabel}" data-price="${pricePerQty}"></div>`);

        template.push (`<div class="builder-form-col2"><span class="builder-option-label" `);
        if (description) {
            template.push (`data-description="${htmlEncode(description)}"`);
        }
        template.push (`data-resource="${htmlEncode(imageUrl)}">${label}</span>`);

        if (videoUrl) {
            template.push (` <img class="builder-option-label" src="${this.baseUrl}video.png" data-resource="${htmlEncode(videoUrl)}" data-description="${htmlEncode(description)}">`);
        }

        if (pricePerQty > 0) {
            template.push (` <span class="builder-option-label-price">($${formatMoney(pricePerQty)})</span>`);
        }
        template.push("</div>");
        template.push("</div>");
    }

    addRadio(template, elementId, optionId, label = "", prependLabel = "", pricePerQty = 0, description = "", videoUrl = "", imageUrl = "", defaultvalue = "", checkboxgroup = "") {
        var isChecked = (defaultvalue == "1") ? " checked='checked' " : "";
        template.push( `<div class="builder-form-option">
    <div class="builder-form-col1">
        <input type="radio" 
        name="radio-${optionId}" 
        ${isChecked} 
        data-oid="${optionId}" 
        data-checkboxgroup="${checkboxgroup}" 
        id="${elementId}" 
        class="builder-input builder-input-checkbox" 
        value="1" 
        data-label="${label}" 
        data-prependlabel="${prependLabel}"
        data-price="${pricePerQty}">
    </div>`);

        template.push (`<div class="builder-form-col2"><span class="builder-option-label" `);
        if (description) {
            template.push (`data-description="${htmlEncode(description)}"`);
        }
        template.push (`data-resource="${htmlEncode(imageUrl)}">${label}</span>`);

        if (videoUrl) {
            template.push (` <img class="builder-option-label" src="${this.baseUrl}video.png" data-resource="${htmlEncode(videoUrl)}" data-description="${htmlEncode(description)}">`);
        }

        if (pricePerQty > 0) {
            template.push (` <span class="builder-option-label-price">($${formatMoney(pricePerQty)})</span>`);
        }
        template.push("</div>");
        template.push("</div>");
    }

    addOption(template, option) {
        if (option.max_quantity == "0") {
            this.addRadio(template, "opt" + option.oid, option.oid, option.title, "", option.cost, option.description, option.url_video, option.image, option.defaultvalue, option.checkboxgroup);
        } else if (option.max_quantity == "1") {
            this.addCheckbox(template, "opt" + option.oid, option.oid, option.title, "", option.cost, option.description, option.url_video, option.image, option.defaultvalue, option.checkboxgroup);
        } else {
            var choices = [];
            for (var i=0; i<=option.max_quantity; i++) {
                choices.push({
                    key: i,
                    value: i,
                    price: i * option.cost,
                    prependLabel: "",
                    selected: (option.defaultvalue == i)
                });
            }
            this.addDropdown(template, "opt" + option.oid, option.oid, choices, option.title, option.cost, option.description, option.url_video, option.image);
        }
    }

    addModalToDocument() {

        var modal = `<div id="modal-container" role="dialog">
            <div id="modal-overlay" class="modal-close"></div>
            <div id="modal-contact" role="document">
                <div id="modal-close" class="modal-close">X</div>

                <div class="contactform-header">
                    <div id="modal-header">Review My Trailer</div>
                </div>
                <p class="modal-summary-instructions">Even if it's not complete, or if you're just not sure about something, we can help you out! Send this summary to Double D and the sales team will contact you to answer any questions and help finalize your design!</p>
                <hr>
                <form id="dd-builder-finish-form" class="contactform-form" method="post" action="${this.baseUrl}api/builder/submit">
                    <input type="hidden" name="affiliate" value="${this.affiliate}">
                    <input type="hidden" name="trailerdetails" id="trailerdetails" value="">
                    <div class="modalcontent" id="trailersummary"></div>
                    <input type="text" class="modal-input-text" name="name" placeholder="Name *" required>
                    <input type="email" class="modal-input-text" name="email" placeholder="Email *" required>
                    <input type="tel" class="modal-input-text" name="telephone" placeholder="Telephone *" required>
                    <input type="text" class="modal-input-text" name="city" placeholder="City">
                    <input type="text" class="modal-input-text" name="state" placeholder="State">
                    <textarea type="text" class="modal-input-textarea" name="comments" placeholder="Questions or comments?"></textarea>
                    <input type="submit" class="modal-submitbutton" id="dd-builder-finish-button" value="Submit">
                </form>
                
                <form id="dd-builder-finish-form-external" method="post" action="${this.external_submit_url}">
                    <input type="hidden" name="trailerdetails" id="dd-builder-finish-form-external-trailerdetails" value="">
                    <input type="hidden" name="trailerdetails-html" id="dd-builder-finish-form-external-trailerdetails-html" value="">
                </form>

            </div>
            <div id="modal-infobox">
                <div id="modal-close" class="modal-close">X</div>
                <div id="modal-infobox-content"></div>
            </div>
        </div>`;

        document.getElementById("dd-summary").insertAdjacentHTML('afterend', modal);

        document.getElementById("dd-builder-finish-form").addEventListener("submit", (evt) => {
            this.applyDesignedTag();
            var theForm = evt.target;
            fetch(theForm.action, {
                method: 'POST',
                body: new FormData(theForm),
            }).then(function (response) {
                if (response.ok) {
                    window.alert("Your trailer build has been received. We'll be in touch shortly (Or feel free to continue trying new configurations)");
                    window.history.back();
                } else {
                    return Promise.reject(response);
                }
            }).catch(function (error) {
                window.alert("Sorry, there was an error submitting your form. Please try again, or visit us at https://doubledtrailers.com/")
            });

            evt.preventDefault();
        });

        window.addEventListener("popstate", () => {
            if(window.location.hash != "#modal") {
                this.closeModal();
            }
        });

        var modalTrigger = document.querySelectorAll('.modal-close');
        modalTrigger.forEach((trigger) => {
            trigger.addEventListener('click', (evt) => {
                window.history.pushState('builder', null, this.pageUrl);
                this.closeModal(); // Just to be safe
            });
        });
    }

    openModal() {
        document.getElementById("modal-contact").style.display="block";
        document.getElementById("modal-infobox").style.display="none";
        document.getElementById("modal-container").classList.add("open");

        window.history.pushState('popup', null, this.pageUrl + '#modal');
    }

    openInfobox(resource, description, previousElement, nextElement) {

        var content = "";
        if (resource.indexOf("youtube") > 0) {
            content += `<iframe class="modal-infobox-iframe" width="480" height="360" src="${resource}?autoplay=1" frameborder="0" allowfullscreen="" id="mediaiframe-video"></iframe>`;
        } else if (resource.indexOf(".jpg") > 0 || resource.indexOf(".jpeg") > 0|| resource.indexOf(".png") > 0 || resource.indexOf(".gif") > 0) {
            content += `<img class="modal-infobox-image" src="${resource}" />`;
        } else {
            content += `<iframe class="modal-infobox-webiframe" src="${resource}" frameborder="0" allowfullscreen="" id="mediaiframe-web"></iframe>`;
        }
        if (description) {
            content += `<hr><div class='modal-infobox-description'>${description}</div>`;
        }

        if (previousElement || nextElement) {
            if (previousElement) {
                content += `<div data-series="${previousElement.dataset.series}" data-seriesindex="${previousElement.dataset.seriesindex}" class='series-arrow series-arrow-left'><svg viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><path d="M10 20A10 10 0 1 0 0 10a10 10 0 0 0 10 10zm1.289-15.7 1.422 1.4-4.3 4.344 4.289 4.245-1.4 1.422-5.714-5.648z"/></svg></div>`;
            }
            if (nextElement) {
                content += `<div data-series="${nextElement.dataset.series}" data-seriesindex="${nextElement.dataset.seriesindex}" class='series-arrow series-arrow-right'><svg viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><path d="M10 20A10 10 0 1 0 0 10a10 10 0 0 0 10 10zM8.711 4.3l5.7 5.766L8.7 15.711l-1.4-1.422 4.289-4.242-4.3-4.347z"/></svg></div>`;
            }
        }
        document.getElementById("modal-infobox-content").innerHTML = content;
        document.getElementById("modal-contact").style.display="none";
        document.getElementById("modal-infobox").style.display="block";
        document.getElementById("modal-container").classList.add("open");
        document.querySelectorAll(".series-arrow").forEach(x=> {
            x.addEventListener("click", function() {
                var series = x.dataset.series;
                var index =  parseInt(x.dataset.seriesindex);
                if (series && !isNaN(index)) {
                    var targetElement = document.querySelector(`[data-series='${series}'][data-seriesindex='${index}']`);
                    if (targetElement) {
                        targetElement.click();
                    }
                }
            });
        });
        window.history.pushState('popup', null, this.pageUrl + '#modal');
    }

    closeModal() {
        document.getElementById("modal-container").classList.remove("open");
        var content = document.getElementById("modal-infobox-content");
        while (content.firstChild) {
            content.removeChild(content.firstChild);
        }
    }

    loadAnalytics() {
        if (!this.analyticsLoaded) {
            this.analyticsLoaded = true;
            var gtagScript = document.createElement('script');
            gtagScript.setAttribute('src',"https://www.googletagmanager.com/gtag/js?id=AW-1072261060");
            document.head.appendChild(gtagScript);
            window.dataLayer = window.dataLayer || [];
            window.gtag = function(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-1072261060');
        }
    }

    applyInteractionTag() {
        this.loadAnalytics();
        window.gtag('event', 'conversion', { 'send_to': 'AW-1072261060/o7rOCNyhtO8BEMTPpf8D', });
    }

    applyContactTag() {
        this.loadAnalytics();
        window.gtag('event', 'conversion', { 'send_to': 'AW-1072261060/ZYckCKrMo-8BEMTPpf8D', });
    }

    applyDesignedTag() {
        this.loadAnalytics();
        window.gtag('event', 'conversion', { 'send_to': 'AW-1072261060/5eoFCL-ctO8BEMTPpf8D', });
    }

    saveTrailerState() {
        var data = {};
        document.getElementById("dd-builder").querySelectorAll(".builder-input").forEach(element => {
            var optId = element.dataset.oid;
            var optionType = element.tagName.toLowerCase();
            var theChoice = "";
            if (optionType == "input") {
                if (element.type == "text") {
                    if (element.value) {
                        theChoice = element.value;
                    }
                } else if (element.type == "checkbox") {
                    theChoice =  (element.checked) ? 1 : 0;
                } else if (element.type == "radio") {
                    if (element.checked) {
                        theChoice =  (element.checked) ? 1 : 0;
                    }
                }
            } else if (optionType == "select") {
                theChoice = element.options[element.selectedIndex].value;
            }
            data["opt" + optId] = theChoice;
        });
        // console.log(JSON.stringify(data, null, '\t'));
        localStorage["trailer-" + this.currentModel] = JSON.stringify(data, null, '\t');
    }

    getPresets() {
        try {
            return JSON.parse(localStorage["trailer-" + this.currentModel] ?? "");
        }
        catch {
            return {};
        }
    }

}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

function isSelected(bool) {
    return (bool) ? " selected " : "";
}
function isChecked(bool) {
    return (bool) ? " checked " : "";
}

function htmlEncode(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

let slideUp = (target, duration=500) => {
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout( () => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

let slideDown = (target, duration=500) => {
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;

    if (display === 'none')
        display = 'block';

    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout( () => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

var slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}

const apiKey = document.getElementById("dd-builder").dataset.key;
const tb = new TrailerBuilder(apiKey);
